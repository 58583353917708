import React, { useEffect, useState } from 'react';
import './Menu.css';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { auth, googleAuthProvider, twitterAuthProvider, db } from './firebase';
import Logo from './images/Logo.png';
import { FaBars, FaCrown, FaSun, FaMoon } from 'react-icons/fa';  // Import FontAwesome beer icon
import { FaXmark } from 'react-icons/fa6';
import { useTheme } from './ThemeContext';

function Menu() {
  const [user, setUser] = useState(null);
  const [daysWithUs, setDaysWithUs] = useState(0);  // State to hold the days count
  const [currentPlan, setCurrentPlan] = useState('Free');
  const [planLimit, setPlanLimit] = useState(2);
  const [dailyRefreshTime, setDailyRefreshTime] = useState('');
  const [rateAmount, setRateAmount] = useState(null);  // State to hold the rate amount
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu open/close
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme(); // Use theme and toggleTheme
  const isNight = theme === 'night';

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        console.log("Photo URL:", currentUser.photoURL);
        const userRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          const regDate = userData.regDate.toDate();
          const today = new Date();
          const days = Math.floor((today - regDate) / (1000 * 60 * 60 * 24));
          setDaysWithUs(days);

          const status = userData.status || 'free'; // Provide a default value if status is not defined
          setCurrentPlan(status.charAt(0).toUpperCase() + status.slice(1));
          const limits = { free: 2, premium: 20, elite: 50 };
          setPlanLimit(limits[status.toLowerCase()] || 2); // Default to 2 if status is not recognized

          // Ensure rateAmount is set properly, default to 0 if not present
          setRateAmount(userData.rateAmount || 0);

          calculateDailyRefresh();
          setIsLoadingUser(false);
        } else {
          // Handle the case when the document does not exist
          setIsLoadingUser(false);
          // Possible default or placeholder data could be set here
          setRateAmount(0); // Default rate amount

        }
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen); // Toggle menu open/close

  const handleAuth = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        const isGoogleProvider = provider.providerId === 'google.com';
        const isTwitterProvider = provider.providerId === 'twitter.com';

        const newUserDetails = {
          email: isGoogleProvider ? user.email : '',
          userName: isTwitterProvider ? user.displayName : '',
          rateAmount: 2,
          regDate: serverTimestamp(),
          status: 'Free'
        };

        await setDoc(userRef, newUserDetails);

        // Immediately update the state with the new rateAmount after document creation
        setRateAmount(newUserDetails.rateAmount);
      } else {
        // This will update the rateAmount state based on the existing document
        setRateAmount(docSnap.data().rateAmount);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const calculateDailyRefresh = () => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const millisecondsToMidnight = tomorrow - now;
    setTimeout(() => {
      setDailyRefreshTime('0h 0m 0s');  // Reset at midnight
      calculateDailyRefresh();  // Recalculate next reset
    }, millisecondsToMidnight);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const diff = tomorrow - now;
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setDailyRefreshTime(`${hours}h ${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (
    <div className='menu'>
      <div className='menuLogo'>
        <div className='imgPlace'><img src={Logo} className='imgLogo' /></div>
        <div><span className='scoreText'>Score</span><span className='meText'>Me</span></div>
      </div>
      <div className="theme-toggle">
        <input
          type="checkbox"
          id="theme-toggle-checkbox"
          className="theme-toggle-checkbox"
          checked={isNight}
          onChange={toggleTheme}
        />
        <label className="theme-toggle-label" htmlFor="theme-toggle-checkbox">
          <FaSun className="icon sun-icon" />
          <FaMoon className="icon moon-icon" />
          <div className="ball"></div>
        </label>
      </div>

      <div className='menuItem'>
        <div className='menuButtonItem' onClick={() => navigate('/')}>Rate</div>
        <div className='menuButtonItem' onClick={() => navigate('/feed')}>Feed</div>
        <div className='menuButtonItem' onClick={() => navigate('/dashboard')}>Dashboard</div>
      </div>
      <div className='menuLogin'>
        {user ? (
          <>
            <div className='displayMenuDiv'>
              <div className='amountCredentials'>Credentials: {!isLoadingUser ? (rateAmount !== null ? rateAmount : 'Not available') : 'Loading...'}</div>
              <div className='buyCredits'>Upgrade</div>
              <div className='photoProfile'>{user && user.photoURL && (
                <img src={user.photoURL} referrerPolicy="no-referrer" alt="Profile" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
              )}</div>
              <div className='menuIcon' onClick={toggleMenu}>
                {isMenuOpen ? <i className='icon-cross'><FaXmark /> </i> : <i className='icon-hamburger'><FaBars /></i>}
              </div>
              {isMenuOpen && (
                <div className='modalMenu'>
                  <div className='menuBlock'>Welcome, {user.displayName || user.email}!</div>
                  <div className='menuBlock'>Days with us: <span>{daysWithUs}</span></div>
                  <div className='menuBlock'>Current Plan: <span>{currentPlan}</span></div>
                  <div className='menuBlock'>Plan Limit: <span>{planLimit}</span></div>
                  <div className='menuBlock'>Credentials: <span>{rateAmount}</span></div>
                  <div className='menuBlock'>Daily Refresh: <span>{dailyRefreshTime}</span></div>
                  <div className='menuBlock'><div className='menuUpgradeButton'>Upgrade <FaCrown /> </div></div>
                  <div className='menuBlock'><div className='authMenuButton'><span onClick={handleSignOut}>Sign Out</span></div></div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <button onClick={() => handleAuth(googleAuthProvider)}>Login with Google</button>
            <button onClick={() => handleAuth(twitterAuthProvider)}>Login with Twitter</button>
          </>
        )}
      </div>
    </div>
  );
}

export default Menu;
