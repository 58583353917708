import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDrwOK1UBebXKXh_PBqoe_wMEyRJnXW0z8",
    authDomain: "rateme-f61b9.firebaseapp.com",
    projectId: "rateme-f61b9",
    storageBucket: "rateme-f61b9.appspot.com",
    messagingSenderId: "641326334074",
    appId: "1:641326334074:web:bd42837fbbc926494ebbc3",
    measurementId: "G-2Z84TB0DCQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);



// Auth
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const twitterAuthProvider = new TwitterAuthProvider();
export const db = getFirestore(app);

export default app;

