import React, { useEffect, useState, useCallback } from 'react'; // Import useCallback
import { db, auth } from './firebase';
import { collection, query, getDocs, orderBy, doc, updateDoc, where } from 'firebase/firestore';
import './Dashboard.css';
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json'; // Adjust the path as necessary


function Dashboard() {
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);


    const fetchResponses = useCallback(async () => {
        setIsLoading(true);
        const user = auth.currentUser;
        if (user) {
            try {
                const q = query(
                    collection(db, `history/${user.uid}/responses`),
                    orderBy("dateResponse", "desc"),
                    where("saveResponse", "==", true)  // Adding a condition to filter by `saveResponse`
                );
                const querySnapshot = await getDocs(q);
                const fetchedResponses = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data,
                        parsedTextResponse: processTextResponse(data.textResponse) // Directly assign the processed response
                    };
                });
                setResponses(fetchedResponses);
            } catch (error) {
                console.error("Error fetching responses:", error);
            }
        }
        setIsLoading(false);
    }, []);

    function DeleteConfirmationModal({ isOpen, onDelete, onCancel }) {
        if (!isOpen) return null;

        return (
            <div className="modalBackground">
                <div className="modalContainer">
                    <h2>Are you sure you want to delete this post?</h2>
                    <div className="modalButtons">
                        <button onClick={onDelete}>Yes</button>
                        <button onClick={onCancel}>No</button>
                    </div>
                </div>
            </div>
        );
    }

    const promptDelete = (id) => {
        setSelectedId(id);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        if (selectedId) {
            const user = auth.currentUser;
            if (user) {
                const responseRef = doc(db, `history/${user.uid}/responses`, selectedId);
                try {
                    await updateDoc(responseRef, { saveResponse: false });
                    console.log(`Save response for ${selectedId} updated to false`);
                    fetchResponses();
                } catch (error) {
                    console.error("Error updating document: ", error);
                }
            }
            setShowDeleteModal(false);
            setSelectedId(null);
        }
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
        setSelectedId(null);
    };

    console.log("Final responses to render:", responses);

    useEffect(() => {
        fetchResponses();
    }, [fetchResponses]); // Pass fetchResponses as a dependency

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    // List of all criteria
    const criteriaList = [
        "Design Foundations",
        "Visual Hierarchy",
        "Layout and Spacing",
        "Color",
        "Typography",
        "Theory",
        "Depth",
        "Design Assets",
        "Details"
    ];

    const processTextResponse = (textResponse) => {
        const sections = {};
        const responseLines = textResponse.split('\n');
        let currentSectionTitle = null;
        let currentText = [];
        let currentScore = null;

        responseLines.forEach(line => {
            const scoreMatch = line.match(/Score: (\d+(?:\/\d+)?)/); // Matches '80', '80/100'
            if (scoreMatch) {
                currentScore = scoreMatch[1]; // Capture just the numeric score
                // Skip adding this line to the currentText
                return; // This prevents the score line from being added to the text body
            }
            const foundTitle = criteriaList.find(title => line.startsWith(title)) || (line.startsWith("Overall Feedback") ? "Overall Feedback" : null);
            if (foundTitle) {
                if (currentSectionTitle !== null) {
                    sections[currentSectionTitle] = {
                        text: currentText.join('\n'),
                        score: currentScore
                    };
                }
                currentSectionTitle = foundTitle;
                currentText = [];
                currentScore = null; // Reset score for the next section
            } else {
                currentText.push(line);
            }
        });

        if (currentSectionTitle !== null) {
            sections[currentSectionTitle] = {
                text: currentText.join('\n'),
                score: currentScore
            };
        }

        return sections;
    };

    const deleteResponse = async (id) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${user.uid}/responses`, id);
            try {
                await updateDoc(responseRef, {
                    saveResponse: false
                });
                console.log(`Save response for ${id} updated to false`);
                fetchResponses(); // Refresh the UI by fetching the updated data
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    const togglePost = async (id, currentPostStatus) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${user.uid}/responses`, id);
            try {
                await updateDoc(responseRef, {
                    postResponse: !currentPostStatus
                });
                console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);

                // After updating, fetch the responses again to refresh the UI
                fetchResponses();
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    return (
        <div className='dashboard'>
            {isLoading ? (
                <div className="loader">
                    <Lottie options={defaultOptions} height={400} width={400} />
                </div>
            ) : (
                responses.map((response) => (
                    <div key={response.id} className="responseCard">
                        <div className='titleInformationBox'>
                            <div className="date">Author: {response.email}</div>
                            <div className="date">{response.siteUrl}</div>
                            <div className="date">{response.dateResponse?.toDate().toLocaleString()}</div>
                        </div>
                        <div className='divider'></div>
                        <div className='responseCardSections'>
                            <div className='responseBoxBoard'>
                                <img src={response.imageUrl} alt="Response Screenshot" className='responseImage' />
                            </div>
                            <div className='responseBoxBoardRight'>
                                {Object.entries(response.parsedTextResponse).map(([key, { text, score }], index) => (
                                    <div key={index} className='responseBox'>
                                        <h3>{key}{score && <span className="scoreStyle"> {score}</span>}</h3>
                                        <p style={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>{text}</p>
                                    </div>
                                ))}

                            </div>
                            <DeleteConfirmationModal
                                isOpen={showDeleteModal}
                                onDelete={confirmDelete}
                                onCancel={cancelDelete}
                            />

                        </div>
                        <div className='actionPostButtons'>
                            <div className='actionButton' onClick={() => togglePost(response.id, response.postResponse)}>
                                {response.postResponse ? 'Unpost' : 'Post'}
                            </div>
                            <div className='actionButton'>
                                Share
                            </div>
                            <div className='actionButton' onClick={() => promptDelete(response.id)}>
                                Delete
                            </div>
                        </div>
                        {response.postResponse && <div className="likes">Likes: {response.likeResponse || 0}</div>}
                    </div>
                ))

            )}
        </div>
    );
}

export default Dashboard;
