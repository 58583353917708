import React, { useEffect, useState } from 'react';
import './Footer.css';

function Footer() {

  return (
    <div className='footerArea'>
      <div className='footerDivider'></div>
      <div className='footerContentArea'>
      <div className='footerLinks'>
        <h3>Links</h3>
        <div>
            Our Service
        </div>
        <div>
            FAQ
        </div>
        <div>
            Sign up
        </div>
      </div>
      <div className='footerContact'>
            <h3>Contact us</h3>
            <div>
                manager@scoreme.domain
            </div>
      </div>
      <div className='footerSocial'>
            <h3>Social Network</h3>
            <div>
                Twitter
            </div>
            <div>
                Product Hunt
            </div>
            <div>
                LinkedIn
            </div>
      </div>
      </div>
    </div>
  );
}

export default Footer;
