import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Main from './Main';
import Feed from './Feed';
import Footer from './Footer';
import { ThemeProvider } from './ThemeContext';

function App() {
  return (
    <ThemeProvider>
    <Router>
      <div className="App">
        <header className="App-header">
          <Menu />
        </header>
        <body>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/feed" element={<Feed />} />
          </Routes>
        </body>
        <footer>
          <Footer/>
        </footer>
      </div>
    </Router>
    </ThemeProvider>
  );
}

export default App;
