import React, { useState } from 'react';
import './App.css';
import Rate from './Rate';
import Product from './Product'; // Ensure you import the Product component
import Menu from './Menu';
import Dashboard from './Dashboard';

function Main() {
  const [selectedOption, setSelectedOption] = useState('website');

  return (
    <div>
      <h1 className='mainTitle'>Rate your product!</h1>
        <p className='secondTitle'>Here's a comprehensive overview of your design with actionable feedback to help you improve.</p>
        <div className='selectorProduct'>
        <select onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
          <option value="website">Website</option>
          <option value="product">Product</option>
        </select>
        </div>
        {selectedOption === 'website' ? <Rate /> : <Product />}
    </div>
  );
}

export default Main;
